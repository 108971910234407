<!--
 * @Descripttion: 
 * @Author: wangws
 * @Date: 2024-07-08 09:33:31
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-27 14:40:22
 * @FilePath: AHeader.vue
-->
<template>
  <div class="header-wrapper">
    <p class="header-left" v-if="pathFlag=='check'">
      <img src="../assets/vpcs.png" class="w-10" />
    </p>
    <p @click="turnTo('/')" class="header-left" v-if="pathFlag!='check'">
      <img src="../assets/logo.png" class="w-8" />
      <span class="text-com"></span>
      <span class="text-pt">AI写作指导平台</span>
    </p>
    <div v-if="!getIsMobile" class="header-right">
      <p @click="turnTo('check')">维普查重</p>
      <p @click="turnTo('aigc')">降重/降AIGC率</p>
      <p @click="showOrders">我的订单</p>
      <!-- <p>范文样例</p> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "AHeader",
//   props: {
//     isMobile: {
//       type: Boolean,
//       default: false,
//     },
//   },
  computed: {
        getIsMobile() {
            return this.$store.state.isMobile;
        },
    },
//   watch: {
//     isMobile: function (newVal, oldVal) {
//       console.log(newVal)
//     },
//   },
  data() {
    return {
      url: "",
      pathFlag:"",
    };
  },
  methods: {
    turnTo(path) {
      this.pathFlag = path;
      this.$router.push({path: path, cacheKey:Date.now()})
    },
    showOrders() {
      this.$emit("openOrders")
    }
  }
};
</script>
<style lang="less" scoped>
.header-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  height: 3rem !important;
  width: 100%;

  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(229, 231, 235);
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .header-left {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0.5rem 0;
    cursor: pointer;
    .w-8 {
      width: 5rem;
    }
    .text-com {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
    .text-pt {
      font-size: 0.8rem;
      font-weight: 400;
      margin-left: 0.5rem;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    padding-right: 2rem;
    p {
      font-size: 1rem;
      font-weight: 600;
      margin-right: 1.5rem;
      cursor: pointer;
      &:hover {
        color: rgb(59 130 246);
      }
    }
  }
}
</style>