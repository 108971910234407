<!--
 * @Descripttion: 
 * @Author: wangws
 * @Date: 2024-07-05 14:41:14
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-27 15:46:42
 * @FilePath: App.vue
-->
<template>
  <div ref="app" id="app">
    <a-header @openOrders="openOrders" ></a-header>
<!--    <day-count></day-count>-->
    <customer-service></customer-service>
    <div class="bg"></div>
    <router-view></router-view>
    <!-- <div class="lxfs" v-on:mouseenter="onMouseEnter" v-on:mouseleave="onMouseLeave">
      <svg
        data-v-5dae242e=""
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        class="mx-auto text-3xl text-slate-500 iconify iconify--mingcute"
        width="2em"
        height="2em"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path
            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
          ></path>
          <path
            fill="currentColor"
            d="M5 9a7 7 0 0 1 14 0v1.035c1.696.243 3 1.702 3 3.465v.25c0 1.775-1.531 3.331-3.332 3.248c-.74 2.12-2.622 3.549-4.653 3.911c-.47.172-1.026.091-1.515.091a1.5 1.5 0 0 1 0-3c.793 0 1.671-.115 2.207.609C16.003 17.992 17 16.689 17 15V9A5 5 0 0 0 7 9v6.25A1.75 1.75 0 0 1 5.25 17A3.25 3.25 0 0 1 2 13.75v-.25a3.5 3.5 0 0 1 3-3.465z"
          ></path>
        </g>
      </svg>
      <p>售后客服</p>
      <p>商务合作</p>
    </div>
    <div v-show="showLx" class="lxfs-pic">
    </div> -->
    <orders :orderDialogVisible="orderDialogVisible" @closeOrders="closeOrders" @payNow="payNow"></orders>
  </div>
</template>

<script>
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import AHeader from "./components/AHeader.vue";
//import DayCount from "./components/DayCount.vue";
import CustomerService from "./components/CustomerService.vue";
import Orders from './components/Orders.vue'

export default {
  name: "App",
  components: {
    AHeader,
    //DayCount,
    CustomerService,
    Orders,
  },
  data() {
    return {
      isMobile: false,
      showLx: false,
      orderDialogVisible: false,
    };
  },
  created() {
    this.setDevId();
  },
  mounted() {
    const width = this.$refs.app.offsetWidth;
    if (width < 768) {
      this.$store.commit("changeCommit", true);
    } else {
      this.$store.commit("changeCommit", false);
    }
    window.addEventListener("resize", this.handleResize);
    // setTimeout(() => {
    //   let scrollElem = this.$refs.app;
    //   alert(scrollElem);
    //   scrollElem.scrollTo({ top: 500, behavior: "smooth" });
    // }, 50);
  },
  methods: {
    async setDevId() {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;
      // console.log("唯一标识", visitorId);
      this.$store.commit("setDevId", visitorId);
      // return visitorId;
    },
    handleResize() {
      const width = this.$refs.app.offsetWidth;
      // const height = this.$refs.app.offsetHeight;
      // console.log("盒子宽度:", width, "盒子高度:", height);
      if (width < 768) {
        this.$store.commit("changeCommit", true);
      } else {
        this.$store.commit("changeCommit", false);
      }
    },
    onMouseEnter() {
      this.showLx = true;
    },
    onMouseLeave() {
      this.showLx = false;
    },
    openOrders() {
      this.orderDialogVisible = true;
    },
    closeOrders() {
      this.orderDialogVisible = false;
    },
    payNow(data) {
      alert(data.id)
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  .bg {
    width: 55rem;
    height: 30rem;
    position: fixed;
    top: -8rem;
    left: -7rem;
    rotate: -12deg;
    --tw-blur: blur(64px);
    --tw-gradient-from: #f0f9ff;
    --tw-gradient-to: rgb(240 249 255 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
    transform-origin: top right;
    filter: var(--tw-blur);
    z-index: -1;
  }
  .lxfs {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0.25rem;
    background-color: rgb(255 255 255);

    font-size: 0.75rem;
    line-height: 1rem;
    cursor: pointer;
    p {
      margin: 0;
    }

    color: rgb(100 116 139);
    border: 1px solid rgb(243 244 246);
  }
  .lxfs-pic {
    position: fixed;
    right: 6rem;
    bottom: 2rem;
    width: 6rem;
    height: 5rem;
    background: url("./assets/kf.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
body {
  margin: 0;
}
html,
body,
#app {
  height: 100%;
}
</style>
