/*
 * @Descripttion: 
 * @Author: wangws
 * @Date: 2024-07-05 14:41:14
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-24 12:47:26
 * @FilePath: main.js
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "./router";
import store from './store';
import { download } from '@/utils/service'

Vue.config.productionTip = false
Vue.prototype.download = download
Vue.use(ElementUI,  { size: 'medium' });

Vue.component('CheckRepetion', require('./views/CheckRepetion.vue'));
// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
