/*
 * @Descripttion: 
 * @Author: wangws
 * @Date: 2024-07-08 17:43:11
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-12 09:59:45
 * @FilePath: index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
 
// 用来存储数据
const state = {
 isMobile: false,
 devId: null,
 yqm:null
}
// 响应组件中的事件
const actions = {
    changeDispatch(context, value) {
        console.log('actions', context, value);
        // 将数据 commit 给 mutations
        // 设置 2 个实参: commit 中的方法名 & 发送的数据
        context.commit('changeCommit', value);
    },
}
// 操作数据
const mutations = {
    changeCommit(state, value) {
        console.log('mutations', state, value);
        state.isMobile = value;
    },
    setDevId(state, value) {
        state.devId = value;
    },
    setYqm(state, value){
        state.yqm = value;
    },
}
// 用来将state数据进行加工
const getters = {
 
}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
 