<template>
  <div class="customer-service" @mouseenter="showService = true" @mouseleave="showService = false">
    <div class="customer-cont">
      <svg data-v-0c09bc1e="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="mx-auto text-3xl text-slate-500 iconify iconify--mingcute" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="currentColor" d="M5 9a7 7 0 0 1 14 0v1.035c1.696.243 3 1.702 3 3.465v.25c0 1.775-1.531 3.331-3.332 3.248c-.74 2.12-2.622 3.549-4.653 3.911c-.47.172-1.026.091-1.515.091a1.5 1.5 0 0 1 0-3c.793 0 1.671-.115 2.207.609C16.003 17.992 17 16.689 17 15V9A5 5 0 0 0 7 9v6.25A1.75 1.75 0 0 1 5.25 17A3.25 3.25 0 0 1 2 13.75v-.25a3.5 3.5 0 0 1 3-3.465z"></path></g></svg>
      <div class="text-xs">
        售后客服
      </div>
    </div>
    <div class="m-box" v-if="showService">
      <img src="../assets/kf.png" style="width: 110px;height: 120px;"/>
      <div class="m-cross"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerService",
  data() {
    return {
      showService:false,
    }
  }
}
</script>

<style scoped>
.customer-service {

}
.customer-cont{
  position: fixed;
  bottom: 80px;
  right: 10px;
  z-index: 1000; /* Ensure it's above other content */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-3xl {
  font-size: 1.875rem;
   line-height:2.25rem;
}
.text-xs {
  font-size: .75rem;
  line-height: 1rem
}
.m-box {
  position: fixed;
  bottom: 55px;
  right: 90px;
  width: 114px;
  height: 120px;
  line-height: 50px;
  border-radius: 4px;
  text-align: center;
  z-index: 10;
}
.m-box-enter-active, .m-box-leave-active {
  transition: opacity .5s;
}
.m-box-enter, .m-box-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.m-cross {
  position: absolute;
  top: 50px;
  left: 105px;
  overflow: hidden;
  width: 13px;
  height: 13px;
  background: #F3F2F7;
  border-right: 1px solid #F3F2F7;
  border-top: 1px solid #F3F2F7;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  filter: drop-shadow(0px 0px 3px #F3F2F7);
  z-index: -1;
}
</style>