<template>
  <div class="low-wrapper">
    <div class="content">
      <el-tabs v-model="activeName"  @tab-click="handleClick">
        <el-tab-pane label="上传文件" name="upload">
          <section style="min-height: 900px;" v-show="uploadProgress == 'toUpload'">
            <h2>
              请选择您需要的查重版
            </h2>
            <el-row :gutter="20">
              <el-col :span="6">
                <div class="ver-image">
                  <a v-if="version == 1427"><span>
                    <img style="margin-top:15px;width: 30px;height: 30px;" src="../assets/ic_right.png">
                  </span></a>
                  <img @click="selVersion(1427)"  src="../assets/dxs.png"/>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="ver-image">
                  <a v-if="version == 1430"><span>
                   <img style="margin-top:15px;width: 30px;height: 30px;" src="../assets/ic_right.png">
                  </span></a>
                  <img @click="selVersion(1430)" src="../assets/yjs.png"/>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="ver-image">
                  <a v-if="version == 1433"><span>
                     <img style="margin-top:15px;width: 30px;height: 30px;" src="../assets/ic_right.png">
                  </span></a>
                  <img @click="selVersion(1433)" src="../assets/bjbb.png"/>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="ver-image">
                  <a v-if="version == 1434"><span>
                    <img style="margin-top:15px;width: 30px;height: 30px;" src="../assets/ic_right.png">
                  </span></a>
                  <img @click="selVersion(1434)" src="../assets/zcrdb.png"/>
                </div>
              </el-col>
            </el-row>
            <div>
              <ul>
                <li class="versionDes dxs1 clearfix" v-if="version == 1427">
                  <b>维普大学生版</b>：中专,大专,本科。<b style="padding-left:10px;">价格</b>：<font style="color:#FF0000">2.7元/1000字符</font>
                </li>
                <li class="versionDes yjs2 clearfix" v-if="version == 1430">
                  <b>维普研究生版</b>：硕士,博士。<b style="padding-left:10px;">价格</b>：<font style="color:#FF0000">2.7元/1000字符</font>
                </li>
                <li class="versionDes bjbb3 clearfix" v-if="version == 1433">
                  <b>维普编辑部版</b>：期刊,图书,会议论文。<b style="padding-left:10px;">价格</b>：<font style="color:#FF0000">2.7元/1000字符</font>
                </li>
                <li class="versionDes zcrdb4 clearfix" v-if="version == 1434">
                  <b>维普职称版</b>：职称评审,已发表。<b style="padding-left:10px;">价格</b>：<font style="color:#FF0000">2.7元/1000字符</font>
                </li>
              </ul>
            </div>
            <section>
              <h4 class="text-left">上传论文：</h4>
            </section>
            <section>
              <div class="el-form-item is-required"><label class="el-form-item__label" style="width: 80px;">文章题目</label>
                <el-input
                    v-model="title"
                    placeholder="请正确填写论文题目，且字数不超过50字"
                    maxlength="50"
                    style="width: 80%"
                    show-word-limit
                ></el-input>
              </div>
              <div class="el-form-item is-required"><label class="el-form-item__label" style="width: 80px;">作 者</label>
                <el-input
                    v-model="author"
                    placeholder="请正确填写作者信息，且字数不超过20字"
                    maxlength="20"
                    style="width: 80%"
                    show-word-limit
                ></el-input>
              </div>
              <div class="el-form-item is-required"><label class="el-form-item__label" style="width: 80px;">论文文件</label>
                <div style="width: 60%;margin-left: 80px;">
                  <el-upload
                      ref="my-upload"
                      :action="uploadFileUrl"
                      class="upload-demo"
                      drag
                      :limit="1"
                      :before-upload="handleBeforeUpload"
                      :on-success="handleUploadSuccess"
                      :show-file-list="false"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      <span v-show="paperFile.fileName==''">将文件拖到此处，或<em>点击上传</em></span>
                      <span v-show="paperFile.fileName!=''">{{paperFile.fileName}}&ensp;&ensp;
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                                   @click.stop="deleteFile()" circle></el-button>
                      </span>
                    </div>
                  </el-upload>
                </div>
              </div>
            </section>
            <section>
              <el-button type="primary" @click="nextBtn()">下一步</el-button>
            </section>
          </section>
          <section style="min-height: 900px;" v-show="uploadProgress == 'toPay'">
            <div style="margin-top: 16px;">
              <el-descriptions class="margin-top" title="核对订单信息" :column="3" border style="font-size: 16px">
                <span slot="title" style="font-size: 18px;"><i class="el-icon-info" style="color: blue;"></i>&ensp;&ensp;核对订单信息</span>
                <el-descriptions-item :span="3" label="系统版本"><span style="color:blue;">{{checkinfo.pname}}</span></el-descriptions-item>
                <el-descriptions-item :span="3" label="文章标题">{{title}}</el-descriptions-item>
                <el-descriptions-item :span="3" label="作者">{{author}}</el-descriptions-item>
                <el-descriptions-item :span="3" label="文件名">{{checkinfo.fileName}}</el-descriptions-item>
                <el-descriptions-item label="字数">{{orderInfo.wordCount}}字</el-descriptions-item>
                <el-descriptions-item label="单价">{{orderInfo.buyerPrice/100}}元</el-descriptions-item>
                <el-descriptions-item label="总价">{{orderInfo.amount/100}}元</el-descriptions-item>
              </el-descriptions>
            </div>
            <hr />
            <div style="margin-top: 30px;" class="text-left">
              <img style="width:18px ;height:18px;" src="../assets/pay.png" />
              <span style="font-size: 18px;font-weight: 700;">支付订单</span>


              <ul class="payul">
                <li class="payli">
                  <div class="payheader">
                    <div >
                      <img src="../assets/weixin.png" />
                      <div class="paystrtipsdiv">
                        <span class="paystrtips">微信</span>
                      </div>
                    </div>
                  </div>
                  <div>
                        <div style="margin: 15px;padding-bottom: 15px;">
                          <div style="display:inline-block;margin-right: 15px;">
<!--                            <div class="qr_box_div" >-->
<!--                              <div class="nei_dev">-->
<!--                                <div class="huohediv">-->
<!--                                  <img src="/\\/loading.gif" />-->
<!--                                  <p class="qr_f_p_css">二维码正在加载中，请稍等</p>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
                            <div style="height: 220px; width: 220px;box-sizing:border-box;padding:6px;border: 1px solid #666;cursor: pointer;"
                              @click="refreshCode();"
                            >
                              <vue-qr
                                  ref="qrCode"
                                  v-if="codeUrl"
                                  :text="codeUrl"
                                  width="210"
                                  height="210"
                              ></vue-qr>
                            </div>
                          </div>
                          <div class="qr_ceb_div">
                            <div class="qr_ce_div_e">请用微信扫码</div>
                            <div class="qr_ce_div_e"><span style="font-weight: 600;">应付金额：</span><span style="color:red;">{{orderInfo.amount/100}}元</span></div>
                            <div class="qr_ce_div_e"><span style="font-weight: 600;">订单号：</span>{{orderInfo.billNo}}</div>
                            <div class="qr_ce_div_e">温馨提示：请保存好订单号 以便 <a href="#" @click="queryBill();">查询报告</a></div>
                              <el-button type="success" @click="queryBill()">我已经付款,下一步</el-button>
                          </div>
                        </div>
                  </div>
                </li>
              </ul>


            </div>
            <section class="text-left" style="margin-top: 40px;">
              <el-button type="info" plain @click="backBtn()">上一步</el-button>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane label="报告下载" name="download">
          <el-main class="mymain" id="maincon">
            <div class="mianbox">
              <el-row>
                <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="20">
                  <div id="main_box" class="mian_show_box">
                    <div class="querytitle text-left">
                      <img src="../assets/order.png" class="icocs" />
                      <span class="titles">报告查询</span>
                      <span class="tipss">填写检测的“订单号”，点击查询报告，然后下载对应的检测报告！</span>
                    </div>
                    <el-form :inline="true" class="queryform text-left">
                      <el-form-item label="订单号">
                        <el-input v-model="orderInfo.billNo" placeholder="请输入订单号"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="queryBill()">查询</el-button>
                      </el-form-item>
                      <el-form-item>
                        <el-popover class="querypopover" placement="bottom-start" trigger="hover">
                          <img class="popimg" src="../assets/wx_out_trade_num.png" />
                          <span slot="reference"><img class="pppoprefimg" src="../assets/reportwx.png" />微信订单号?</span>
                        </el-popover>
                      </el-form-item>
                    </el-form>
                    <hr class="queryhr" />
                    <div class="queryjg">
                      <div class="queryjgw" v-if="orderStatus==0">
                        <p style="font-size: 26px;color:#6a6a6a;">
                          <i class="el-icon-document"></i><span>没有查询出报告！</span>
                          <span style="color: red;" v-if="orderInfo.payStatus == 0">订单未支付！</span>
                        </p>
                        <p style="font-size:12px;color:#6a6a6a;"><br />温馨提示: 请确认输入订单号正确或已成功提交检测，如有疑问请联系在线客服。</p>
                      </div>
                      <div v-if="orderStatus!=0" class="queryjgy">
                        <el-row>
                          <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                            <div class="qordertitle text-left" v-show="orderInfo.title != null && orderInfo.title != ''">
                              {{orderInfo.title}}
                            </div>
                            <div class="qorderinfo text-left"  v-show="orderInfo.author != null && orderInfo.author != ''">
                              作者：{{orderInfo.author}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;付款时间：{{orderInfo.payTime}}
                            </div>
                             <div v-if="orderInfo.state==1" class="querystatusn">
                              <img class="querystatusimg" src="../assets/check-load.png">正在检测
                            </div>
                            <div v-if="orderInfo.state==2" class="querstatusy">
                              <img class="querystatusimg" src="../assets/check-pass.png">检测成功
                            </div>
                            <div v-if="orderInfo.state==3" class="querystatusn">
                              <img class="querystatusimg" src="../assets/check-fail.png">检测失败
                            </div>
                            <div v-if="orderInfo.state==8" class="querystatusn">
                              <img class="querystatusimg" src="../assets/reptuik.png">已经退款
                            </div>
                            <div v-if="orderInfo.state==9" class="querystatusn">
                              <img class="querystatusimg" src="../assets/repdel.png">报告已删除
                            </div>

                          </el-col>
                          <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                            <div v-if="orderInfo.state==2" style="margin: 5px 5px 20px 5px;">
                              <el-button @click="downrep()" type="primary">下载报告</el-button>
                            </div>
                            <div v-if="orderInfo.state==2" style="margin: 20px 5px 5px 5px;">
                              <el-button @click="deleteCheckRecord(orderInfo.id)" type="danger">删除报告</el-button>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                    <el-alert title="提醒：系统仅保留 7 天以内的检测报告，用户检测完成后请尽快下载您的检测报告!" :closable="false" type="error"></el-alert>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-main>

        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="footer">
      <div class="footer-container">
<!--        <p>© 2024 山东鱼谷智能科技有限公司</p>-->
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import {paperCheck,checkRefresh, checkBillNo,delCheckReport} from "../api/common";

export default {
  name: "CheckRepetion",
  computed: {
    getIsMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      activeName: 'upload',
      version:1427,
      title:"",
      author:"",
      uploadFileUrl: "http://127.0.0.1:88/common/upload",
      paperFile:{
        fileName:"",
        fileUrl:"",
      },
      checkinfo:{
        pid: 1427,
        pname:'维普大学生版',
        promoCode:this.$store.state.yqm,
        title:'',
        author:'',
        fileName:'',
      },
      orderInfo: {
      },
      loading: null,
      uploadProgress:'toUpload',
      orderStatus:0,
      timeInter:null,
      sourceData: "",
      genData: "",
      genDis:true,
      payDialogVisible:false,
      codeUrl: "",
      fullscreenLoading: false,
    };
  },
  components: {
    VueQr,
  },
  mounted() {
    let origin = window.location.origin;
    this.uploadFileUrl = `${origin}/api/common/upload`;

    if (this.$route.query.yqm) {
      this.yqm = this.$route.query.yqm;
      this.checkinfo.promoCode = this.yqm;
      this.$store.commit("setYqm", this.yqm);
    }
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == 'upload') {
        if (this.timeInter) {
          clearInterval(this.timeInter);
        }
      }
    },
    //设置版本
    selVersion(ver) {
      this.version = ver;
      this.checkinfo.pid = ver;
      switch (ver) {
        case 1427:
          this.checkinfo.pname = "维普大学生版";
          break;
        case 1430:
          this.checkinfo.pname = "维普研究生版";
          break;
        case 1433:
          this.checkinfo.pname = "维普编辑部版";
          break;
        case 1434:
          this.checkinfo.pname = "维普职称版";
          break;
      }
    },
    handleBeforeUpload(file){
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      var fileType = "txt doc docx";
      if (!fileType.includes(testmsg)) {
        this.$message.error("上传文件只能是txt、doc、docx格式!")
        return false;
      }
    },
    uploadFile(item) {
      var fromData = new FormData();
      fromData.append("file", item);
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      if (res.code === 0) {
        this.paperFile.fileName = res.originalFilename;
        this.paperFile.fileUrl = res.fileName;
        this.checkinfo.fileName = res.originalFilename;
        this.checkinfo.paperFile = res.fileName;
      }
    },
    deleteFile() {
      this.$refs['my-upload'].clearFiles();
      this.paperFile.fileName = "";
      this.paperFile.fileUrl = "";
    },
    //下一步
    nextBtn() {
      if (this.title == "") {
        this.$message.error("文章题目必须输入！");
        return false;
      }
      if (this.author == "") {
        this.$message.error("作者必须输入！");
        return false;
      }
      if (this.paperFile.fileName == "") {
        this.$message.error("必须上传论文文件！");
        return false;
      }
      this.openFullScreen("正在计算字数...");
      this.checkinfo.title= this.title;
      this.checkinfo.author= this.author;

      paperCheck(this.checkinfo).then((res) => {
        console.log(res)
        if (res.code==0) {
          this.codeUrl = res.data.wxPayUnifiedOrderResult.codeURL;
          this.orderInfo = res.data;
          this.orderInfo.wxPayUnifiedOrderResult = null;
          this.uploadProgress = "toPay";
          // setInterval(() => {
          //   this.refreshCode();
          // }, 61 * 1000);
        } else {
          this.$message.error("计算字数失败");
        }
        this.loading.close();
      });
    },
    //上一步
    backBtn() {
      this.title="";
      this.author="";
      this.paperFile.fileName = "";
      this.paperFile.fileUrl = "";
      this.checkinfo.fileName = "";
      this.checkinfo.paperFile = "";
      window.location.reload();
      this.uploadProgress = "toUpload";
    },
    openFullScreen(loadTxt) {
      this.loading = this.$loading({
        lock: true,
        text: loadTxt,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    refreshCode() {
      this.openFullScreen("正在刷新...");
      this.codeUrl="";
      checkRefresh(this.orderInfo.id).then((res) => {
        if (res.code==0) {
          this.codeUrl = res.data.wxPayUnifiedOrderResult.codeURL;
          this.orderInfo = res.data;
          this.orderInfo.wxPayUnifiedOrderResult = null;
        }
        this.loading.close();
      });
    },
    //查报告
    queryBill(){
      if (this.orderInfo.billNo) {
        this.openFullScreen("正在加载...");
        checkBillNo(this.orderInfo.billNo).then((res) => {
          if (res.code==0) {
            this.orderInfo = res.data;
            console.log(this.orderInfo.state)
            if (this.orderInfo.payStatus == 0) {
              this.orderStatus = 0;
            } else {
              this.orderStatus = 1;
            }
            var that = this;
            if (this.orderInfo.state == 1) {
              if (this.timeInter == null) {
                this.timeInter = setInterval(function() {
                  that.queryBill();
                }, 20000);
              }
            }

            if (this.orderInfo.state == 2) {
              if (this.timeInter) {
                clearInterval(this.timeInter);
              }
            }
          } else {
            //this.$message.error(res.msg);
            this.orderStatus = 0;
          }
          this.loading.close();
        });
        this.activeName = "download";
      }
    },
    //下载报告
    downrep() {
      let origin = window.location.origin;
      // this.uploadFileUrl = `http://${host}/api/common/upload`;
      console.log(origin  + '/api/api/paper/download/wpcs/' + this.orderInfo.billNo);
      window.open( origin  + '/api/api/paper/download/wpcs/' + this.orderInfo.billNo);
    },
    deleteCheckRecord(id) {
      this.openFullScreen("正在删除报告...");
      this.codeUrl="";
      delCheckReport(id).then((res) => {
        if (res.code==0) {
          this.orderInfo.state = "9";
        }
        this.loading.close();
      });
    },
    //已支付
    paySuccess() {
      this.payDialogVisible = false;
      this.genDis = false;
      this.codeUrl = "";
      this.loading = true;
      console.log(this.aigcCont); //this.aigcCont.id
    },

  },
};
</script>
<style lang="less" scoped>
.low-wrapper {
  margin: 0 auto;
  height: 100%;
  overflow-y: auto;
  .content {
    padding: 0 1.25rem;
    max-width: 80rem;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 5rem;
    box-sizing: border-box;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  li {
    list-style: none;
  }
  .el-col {
    border-radius: 4px;
  }
  .versionDes {
    text-align: left;
    line-height: 48px;
    background-color: #F0F7FF;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 22px;
    position: relative;
  }
  .versionDes, .versionDes b, .versionDes label, .versionDes p{
    color: #1272D7;
  }
  .versionDes p{
    line-height: 26px;
  }

  .text-left {
    text-align: left;
  }

  .mian_show_box {
    background-color: white;
    margin: 10px 10px 10px 0px;
    padding: 10px;
    box-sizing:border-box;

  }
  .querytitle{
    margin: 10px 5px 15px 15px;
  }

  .querytitle .icocs {
    width:20px ;
    height:20px;
  }

  .querytitle .titles {
    font-size: 18px;
    color:black;
    margin-right:10px;
    font-weight: 700;
    line-height:20px;
  }
  .querytitle .tipss {
    font-size: 12px;
    color:#6a6a6a;
  }

  .footer {
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    .footer-container {
      text-align: left;
      padding-top: 2rem;
      border-top: 1px solid #1118271a;
      p {
        margin: 0;
        font-size: 0.75rem;
        color: rgb(107 114 128);
      }
    }
  }
}

.ver-image {
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  float: left;
  margin-right:36px;
  height: 97px;
  img{
    width:214px;
    height:auto;
  }
  a {
    position: absolute;
    left:0;
    top: 0;
    /*background: url(images/balckdot.png) repeat;*/
    width:215px;
    height: 97px;
    text-align: center;
    span {
      width: 58px;
      height: 58px;
      border-radius: 58px;
      background-color: #fff;
      display:inline-block;
      text-align: center;
      margin-top:18px
    }
  }
}
.price_tips {
  font-size: 12px;
  margin-top: 0px;
}

.price_tips i {
  color: red;
}

.payul {
  list-style: none;
  padding-inline-start: 20px;
  width: 90%;
}
.payli {
  margin-bottom: 20px;
}
.payli:hover {
  box-shadow: 0 0 5px 1px #999;
}
.payheader {
  width: 100%;
  height: 50px;
  font-size: 20px;

}
.payheader img {
  height: 30px;
  margin: 5px;
}
.qr_box_div {
  position: absolute;
  display: inline-block;
  z-index: 1;
  height: 220px;
  width: 220px;
  background-color: rgba(100, 100, 100, 0.9);
}

.qr_box_div .nei_dev {
  display: inline-table;
  height: 220px;
  width: 220px;
}

.huohediv {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.qr_f_p_css {
  color: white;
  font-weight: 600;
}


.file_tips_col {
  border-left: 1px dotted #B0B0B0;
  height: 100%;
}

.price_tips_dev {
  text-align: end;
  margin-top: 0px;
}

.paystrtipsdiv {
  display: inline-table;
  height: 50px;
}

.paystrtipsdiv .paystrtips {
  display: table-cell;
  vertical-align: middle;
}

.qr_ceb_div {
  display: inline-block;
  vertical-align: top;
  height: 220px;
  padding: 10px;
  box-sizing: border-box;
}

.qr_ceb_div .qr_ce_div_e {
  font-size: 16px;
  margin-bottom: 10px;
}
//tabs  每一项元素
/deep/.el-tabs__item {
  font-size: 22px!important;
}

.querytitle{
  margin: 10px 5px 15px 15px;
}

.querytitle .icocs {
  width:20px ;
  height:20px;
}

.querytitle .titles {
  font-size: 18px;
  color:black;
  margin-right:10px;
  font-weight: 700;
  line-height:20px;
}
.querytitle .tipss {
  font-size: 12px;
  color:#6a6a6a;
}

.queryform {
  margin: 20px 5px 15px 15px;
}

.querypopover {
  margin-left: 25px;
}

.querypopover .popimg{
  max-width: 600px;
}

.pppoprefimg {
  width: 21px;
  vertical-align: middle;
  margin-right: 5px;
}

.queryhr{
  background-color:blue;
  height:1px;
  border:none;
  width: 100%;
}

.queryjg {
  display:table;
  min-height:300px;
  width: 100%;
}

.queryjg .queryjgw {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
}

.queryjg .queryjgy {
  margin: 15px 5px 5px 15px;
}

.qordertitle {
  font-size:24px;
  color:black;
  margin-bottom: 10px;
}

.qorderinfo {
  font-size: 14px;
  color:#878787;
  margin-bottom: 10px;
}

.querystatusn {
  font-size: 18px;
  height:30px;
  line-height:30px;
  color:red;
}

.querstatusy {
  font-size: 18px;
  height:30px;
  line-height:30px;
  color:#2ca518;
}

.querystatusimg{
  margin-right:10px;
  vertical-align: middle;
}

</style>