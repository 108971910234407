/*
 * @Description: 
 * @Author: wangwenshuai
 * @Date: 2022-12-15 21:43:43
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-24 18:31:39
 */
import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import { saveAs } from 'file-saver'

axios.defaults.withCredentials = true

const service = axios.create({
  baseURL: '/api', // api的base_url
  // baseURL: 'http://10.204.171.35:8088',
  timeout: 500000, // request timeout
})
//设置请求头避免缓存
service.defaults.headers['Cache-Control'] = 'no-cache';

service.interceptors.request.use(config => {
  return config
}, error => {
  Promise.reject(error)
})

let errorOverDue = false
service.interceptors.response.use((res) => {
  let { data } = res
  return data
}, error => {
  if (errorOverDue) {
    return
  }
  if (error.response && error.response.data) {
    const errorObj = error.response.data
    return Promise.reject(errorObj)
  }
  return Promise.reject(error)
})


export async function download(url, params, filename, config) {
  let downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  // try {
  //   const response = await service.get(url, params, {
  //     responseType: 'blob',
  //   })
    
  //   // const contentDisposition = response.headers.get('content-disposition');
  //   // const fileName = extractFileName(contentDisposition) || filename;
  //   // const contentType = response.headers.get('content-type');
  //   const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
  //   saveAs(blob, filename);
  //   downloadLoadingInstance.close();
  // } catch (error) {
  //   console.error('Error downloading file:', error);
  //   Message.error('下载文件出现错误，请稍后再试！');
  //   downloadLoadingInstance.close();
  // }
  service.get(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

function extractFileName(contentDisposition) {
  if (!contentDisposition) return null;
  const match = /filename="([^"]+)"/g.exec(contentDisposition);
  if (match) return match[1];
  return null;
}

export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}

export { service }