<!--
 * @Descripttion: 
 * @Author: wangws
 * @Date: 2024-07-27 14:51:33
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-27 17:03:46
 * @FilePath: Orders.vue
-->
<template>
  <div>
    <el-dialog
      style="text-align: left"
      title="我的订单"
      class="order-wrapper"
      :visible.sync="visible"
      @close="closeRe"
      width="25%"
    >
      <p>请输入微信支付记录中以“99”的商户订单号</p>
      <el-input
        v-model="orderNum"
        placeholder="请输入商户订单号"
        maxlength="20"
      ></el-input>

      <div class="wj-info" v-if="orderObj.id">
        <div class="wj-top">
          <p>
            订单号：<span>{{ orderObj.billNo }}</span>
          </p>
          <p>{{ orderObj.gmtCreated }}</p>
        </div>
        <p class="wj-title">{{ orderObj.title }}</p>
        <div class="wj-zs">
          <p>[{{ paperTypeObj[orderObj.paperType] }}]</p>
          <span class="dg-view" @click="getOutline(orderObj)"
            >查看大纲
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="text-lg iconify iconify--bitcoin-icons"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                <path
                  d="M5 14.997a.75.75 0 0 1 .75.75V18c0 .138.112.25.25.25h12a.25.25 0 0 0 .25-.25v-2.253a.75.75 0 0 1 1.5 0V18A1.75 1.75 0 0 1 18 19.75H6A1.75 1.75 0 0 1 4.25 18v-2.253a.75.75 0 0 1 .75-.75"
                ></path>
                <path
                  d="M12.202 5.58a.75.75 0 0 1 .75.75v8.086a.75.75 0 0 1-1.5 0V6.331a.75.75 0 0 1 .75-.75"
                ></path>
                <path
                  d="M11.678 4.464a.75.75 0 0 1 1.048 0L16.07 7.73a.75.75 0 0 1-1.048 1.073l-2.82-2.754l-2.82 2.754A.75.75 0 1 1 8.334 7.73z"
                ></path>
              </g>
            </svg>
          </span>
        </div>
        <el-divider></el-divider>
        <div class="zw">
          <p>正文</p>
          <p>{{ statusObj[orderObj.status] }}</p>
        </div>
        <el-button
          class="btn-pay"
          v-if="orderObj.payStatus == 0"
          type="primary"
          @click="payNow(orderObj)"
          round
          v-loading.fullscreen.lock="fullscreenLoading"
          >立即支付</el-button
        >
        <el-button
          class="btn-pay"
          v-if="orderObj.payStatus == 1"
          :disabled="orderObj.status != 2"
          type="primary"
          @click="downloadPaper(orderObj)"
          round
          >下载论文</el-button
        >
      </div>

      <el-button
        class="btn-orders"
        type="primary"
        :disabled="orderNum == ''"
        @click="getOrders"
        round
        >查询订单</el-button
      >
    </el-dialog>
    <el-dialog
      style="text-align: left"
      title="支付订单"
      :visible.sync="payDialogVisible"
      @close="closePay"
      append-to-body
      width="35%"
    >
      <div class="pay-wrapper">
        <div class="pay-left">
          <p style="text-align: center; margin: 0">
            <svg
              data-v-5d9b3dec=""
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="wxzf"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M9.271 14.669a.66.66 0 0 1-.88-.269l-.043-.095l-1.818-3.998a.5.5 0 0 1 0-.146a.327.327 0 0 1 .335-.327a.3.3 0 0 1 .196.066l2.18 1.527a1 1 0 0 0 .546.167a.9.9 0 0 0 .342-.066l10.047-4.5a10.73 10.73 0 0 0-8.171-3.526C6.479 3.502 2 7.232 2 11.87a7.83 7.83 0 0 0 3.46 6.296a.66.66 0 0 1 .24.727l-.45 1.701a1 1 0 0 0-.051.24a.327.327 0 0 0 .334.334a.4.4 0 0 0 .19-.058l2.18-1.265c.16-.098.343-.151.53-.152q.15 0 .292.043c1.062.3 2.16.452 3.264.45c5.525 0 10.011-3.729 10.011-8.33a7.23 7.23 0 0 0-1.098-3.883L9.351 14.625z"
              ></path></svg
            >微信
          </p>
          <vue-qr
            ref="qrCode"
            v-if="codeUrl"
            :text="codeUrl"
            width="180"
            height="180"
          ></vue-qr>
        </div>
        <div class="pay-right">
          <p style="margin: 0; font-weight: 600; font-size: 1rem">
            {{ tmpItemPay.title }}
          </p>
          <p>
            论文类型：<span style="color: rgb(59 130 246)">{{
              paperTypeObj[tmpItemPay.paperType]
            }}</span>
          </p>
          <p>专业内容：{{ chartCnt }}</p>
          <p>
            总金额：<span style="color: rgb(238 99 96); font-size: 30px"
              >{{ tmpItemPay.amount / 100 }}元  </span>&nbsp;&nbsp;
            <span style="color:rgb(153 153 153); font-size: 26px;text-decoration-line:line-through;"
            > {{ tmpItemPay.ext2 / 100 }}元</span>
          </p>
          <el-button class="btn-pay" type="primary" @click="paySuccess" round
            >我已支付成功</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import Utils from "../utils/util";
import {
  getPaperByBill,
  toPaperPay,
} from "../api/common.js";
import { Message, Loading } from "element-ui";

export default {
  name: "Orders",
  props: {
    orderDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueQr,
  },
  data() {
    return {
      visible: false,
      orderNum: "",
      orderObj: {},
      statusObj: {
        0: "未进行",
        1: "生成中",
        2: "已生成",
        3: "删除",
      },
      paperTypeObj: {
        11: "成考/自考（约8千字）",
        12: "专科/本科（约1万字）",
        13: "本科（约2万字）",
        14: "研究生（约3万字）",
        15: "在职硕士（约5万字）",
      },
      payDialogVisible: false,
      tmpItemPay: {},
      codeUrl: "",
      fullscreenLoading: false,
      orderId: "",
      chartCnt: "",
    };
  },
  watch: {
    orderDialogVisible(val) {
      this.visible = val;
    },
  },
  created() {},
  methods: {
    getOutline(data) {
      Utils.$emit("showDg", data);
      this.orderObj = {};
      this.orderNum = "";
      this.visible = false;
      this.$emit("closeOrders");
    },
    closeRe() {
      Utils.$emit("refreshBill", null);
      this.orderObj = {};
      this.orderNum = "";
      this.$emit("closeOrders");
    },
    getOrders() {
      getPaperByBill(this.orderNum).then((res) => {
        if (res.code == 0) {
          this.orderObj = res.data;
        } else {
          Message.error("订单获取失败，请稍后再试！");
        }
      });
    },
    payNow(orderObj) {
      let dataTmp = orderObj;
      this.fullscreenLoading = true;
      this.tmpItemPay = dataTmp;
      toPaperPay(orderObj.id).then((res) => {
        this.orderId = res.data.billNo;
        this.orderNum = res.data.billNo;
        this.orderObj.billNo = res.data.billNo;
        if (res.data.ext1) {
          var chartArr = res.data.ext1.split("|");
          this.chartCnt = '[代码段x' + chartArr[0] + "][公式x" + chartArr[1]
              + "][图x" + chartArr[2] + "][数据(表)x" + chartArr[3] + "]";

        } else {
          this.chartCnt = res.data.title;
        }

        this.codeUrl = res.data.wxPayUnifiedOrderResult.codeURL;
        // alert(this.codeUrl);
        this.fullscreenLoading = false;
      });
      this.payDialogVisible = true;
    },
    paySuccess() {
      this.payDialogVisible = false;
      this.orderNum = this.orderId;
      this.getOrders();
    },
    closePay() {
      this.codeUrl = "";
    },
    downloadPaper(item) {
      // let host = window.location.host;
      // window.open(`http://${host}/api/api/paper/download/word/${item.id}`);
      let origin = window.location.origin;
      console.log(origin  + '/api/api/paper/download/word/' + item.id);
      window.open( origin  + '/api/api/paper/download/word/' + item.id);
    },
  },
};
</script>
<style lang="less" scoped>
.order-wrapper {
  p {
    margin-top: 0;
  }
  .btn-orders {
    margin-top: 1rem;
    margin-left: 16rem;
    text-align: right;
  }
  .wj-info {
    margin-top: 1rem;
    display: flex;
    border-width: 1px;
    border-radius: 0.5rem;
    flex-direction: column;
    border: 1px solid rgb(229 231 235);
    padding: 1rem;
    .wj-top {
      display: flex;
      justify-content: space-between;
      color: rgb(156 163 175);
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .wj-title {
      text-align: left;
      font-weight: 600;
      margin: 0;
    }
    .wj-zs {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      font-size: 0.75rem;
      p {
        margin: 0;
      }
      .dg-view {
        cursor: pointer;
        color: rgb(59 130 246);
        svg {
          vertical-align: middle;
        }
      }
    }
    .zw {
      display: flex;
      justify-content: space-between;
      font-size: 0.75rem;
      p {
        margin: 0;
      }
    }
    .btn-pay {
      width: 8rem;
      font-size: 0.75rem;
      margin-top: 2rem;
    }
  }
}
.pay-wrapper {
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.wxzf {
  color: rgb(7 193 96);
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}
</style>