/*
 * @Description: 路由
 * @Author: wangwenshuai
 * @Date: 2023-10-14 10:32:24
 * @LastEditors: wangws
 * @LastEditTime: 2024-09-02 17:32:03
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.isBack = true

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/aigc",
    name: "aigc",
    component: () => import("@/views/LowRepetion"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/check",
    name: "check",
    component: () => import("@/views/CheckRepetion"),
    meta: {
      keepAlive: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: '/ai-pass-web/',
  routes
});

export default router;